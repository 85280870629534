import React, { useState } from "react";
import { TextField, Button, Box, Typography, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { apiBaseUrl } from "../../constants";
import axios from "axios";
import { toast, ToastContainer } from "material-react-toastify";
import { useNavigate } from "react-router-dom";
export default function AddArticles() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/articles");
  };
  const [formData, setFormData] = useState({
    title: "",
    author: "",
    date: "",
    headings: [{ heading: "", content: "" }],
    resources: [""],
  });

  const handleChange = (e, index, type) => {
    const { name, value } = e.target;
    if (type === "heading") {
      const updatedHeadings = [...formData.headings];
      updatedHeadings[index][name] = value;
      setFormData({ ...formData, headings: updatedHeadings });
    } else if (type === "resource") {
      const updatedResources = [...formData.resources];
      updatedResources[index] = value;
      setFormData({ ...formData, resources: updatedResources });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleAddHeading = () => {
    setFormData({
      ...formData,
      headings: [...formData.headings, { heading: "", content: "" }],
    });
  };

  const handleAddResource = () => {
    setFormData({
      ...formData,
      resources: [...formData.resources, ""],
    });
  };

  const handleRemoveHeading = (index) => {
    const updatedHeadings = formData.headings.filter((_, i) => i !== index);
    setFormData({ ...formData, headings: updatedHeadings });
  };

  const handleRemoveResource = (index) => {
    const updatedResources = formData.resources.filter((_, i) => i !== index);
    setFormData({ ...formData, resources: updatedResources });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formattedContent = formData.headings.reduce((acc, heading, index) => {
      const isContentList = index === 1 || index === 2;
      const content = isContentList
        ? Array.isArray(heading.content)
          ? heading.content
          : [heading.content]
        : heading.content;
      acc[`heading${index + 1}`] = heading.heading;
      acc[`content${index + 1}`] = content;

      return acc;
    }, {});
    const { title, author, date, resources } = formData;
    const hasValidHeadingAndContent = formData.headings.some(
      (heading) =>
        heading.heading.trim() !== "" && heading.content.trim() !== ""
    );
    if (!hasValidHeadingAndContent) {
      toast.error("Please add at least one heading and content.");
      return;
    }
    if (!title || !author || !date || !resources) {
      toast.error("All fields are required");
      return;
    }
    const payload = {
      title: formData.title,
      author: formData.author,
      date: formData.date,
      content: formattedContent,
      resources: formData.resources,
    };

    console.log("Payload:", payload);

    axios
      .post(`${apiBaseUrl}articles`, payload)
      .then((res) => {
        console.log("Article added successfully:", res.data);
        toast.success("Article added successfully");
      })
      .catch((err) => {
        toast.error("Error adding article:", err);
      });
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: "0 auto",
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        "@media (max-width: 600px)": { padding: "10px" },
      }}
    >
      {" "}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />{" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row" },
          gap: "20px",
        }}
      >
        {" "}
        <Typography
          variant="h4"
          fontWeight="bold"
          gutterBottom
          sx={{ textAlign: { xs: "center", sm: "left" } }}
        >
          {" "}
          Article Submission Form{" "}
        </Typography>{" "}
        <Button
          variant="contained"
          type="submit"
          sx={{ backgroundColor: "black", mt: { xs: 2, sm: 0 } }}
          onClick={handleBack}
        >
          {" "}
          Back{" "}
        </Button>{" "}
      </Box>{" "}
      <form onSubmit={handleSubmit}>
        {" "}
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          name="title"
          value={formData.title}
          onChange={handleChange}
          margin="normal"
        />{" "}
        <TextField
          label="Author"
          variant="outlined"
          fullWidth
          name="author"
          value={formData.author}
          onChange={handleChange}
          margin="normal"
        />{" "}
        <TextField
          label="Date"
          variant="outlined"
          fullWidth
          name="date"
          value={formData.date}
          onChange={handleChange}
          margin="normal"
        />{" "}
        {formData.headings.map((heading, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            {" "}
            <TextField
              label={`Heading ${index + 1}`}
              variant="outlined"
              fullWidth
              name="heading"
              value={heading.heading}
              onChange={(e) => handleChange(e, index, "heading")}
              margin="normal"
            />{" "}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              {" "}
              <TextField
                label={`Content for Heading ${index + 1}`}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                name="content"
                value={heading.content}
                onChange={(e) => handleChange(e, index, "heading")}
                margin="normal"
              />{" "}
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                {" "}
                <IconButton
                  sx={{
                    backgroundColor: "black",
                    "&:hover": { backgroundColor: "black", color: "white" },
                  }}
                  onClick={handleAddHeading}
                >
                  {" "}
                  <AddIcon sx={{ color: "white" }} />{" "}
                </IconButton>{" "}
                {formData.headings.length > 1 && (
                  <IconButton
                    sx={{
                      backgroundColor: "black",
                      "&:hover": { backgroundColor: "black", color: "white" },
                    }}
                    onClick={() => handleRemoveHeading(index)}
                  >
                    {" "}
                    <RemoveIcon sx={{ color: "white" }} />{" "}
                  </IconButton>
                )}{" "}
              </Box>{" "}
            </Box>{" "}
          </Box>
        ))}{" "}
        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          {" "}
          Resources{" "}
        </Typography>{" "}
        {formData.resources.map((resource, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            {" "}
            <TextField
              label={`Resource ${index + 1}`}
              variant="outlined"
              fullWidth
              value={resource}
              onChange={(e) => handleChange(e, index, "resource")}
              margin="normal"
            />{" "}
            {index === formData.resources.length - 1 && (
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {" "}
                <IconButton
                  onClick={handleAddResource}
                  sx={{
                    ml: 1,
                    backgroundColor: "black",
                    "&:hover": { backgroundColor: "black", color: "white" },
                  }}
                >
                  {" "}
                  <AddIcon sx={{ color: "white" }} />{" "}
                </IconButton>{" "}
                {formData.resources.length > 1 && (
                  <IconButton
                    onClick={() => handleRemoveResource(index)}
                    sx={{
                      ml: 1,
                      backgroundColor: "black",
                      "&:hover": { backgroundColor: "black", color: "white" },
                    }}
                  >
                    {" "}
                    <RemoveIcon sx={{ color: "white" }} />{" "}
                  </IconButton>
                )}{" "}
              </Box>
            )}{" "}
          </Box>
        ))}{" "}
        <Button
          variant="contained"
          type="submit"
          sx={{
            mt: 2,
            backgroundColor: "black",
            width: { xs: "100%", sm: "auto" },
          }}
        >
          {" "}
          Submit{" "}
        </Button>{" "}
      </form>{" "}
    </Box>
  );
}
