import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  CardMedia,
} from "@mui/material";
import firstCourseImage from "../../assets/article.jpg";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import firstCourse from "../../assets/behavioralneuroscience.mp4";
import secondCourse from "../../assets/Neuroanatomy.mp4";
const Courses = () => {
  const [currentCourse, setCurrentCourse] = useState(null); // Holds the currently selected course
  const [openModal, setOpenModal] = useState(false);
  // Handle switching to video view
  const handleViewCourse = (course) => {
    setCurrentCourse(course); // Set the selected course
  };
  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
  };
  // Handle going back to the course list
  const handleBackToCourses = () => {
    setCurrentCourse(null); // Reset to the course list view
  };
  const handleShare = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const courses = [
    {
      title: "Neuroanatomy",
      description:
        "Neuroanatomy is the study of the relationship between structure and function in the nervous system. ",
      image: firstCourseImage,
      video: secondCourse,
    },
    {
      title: "Behavioral Neuroscience",
      description:
        "The field of Behavioral Neuroscience is the study of the biological basis of behavior in humans and animals.",
      image: firstCourseImage,
      video: firstCourse,
    },
  ];

  return (
    <>
      {currentCourse ? (
        // Video screen
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            padding: 2,
            backgroundColor: "#f5f5f5",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleBackToCourses}
            sx={{
              mb: 2,
              backgroundColor: "black",
              width: "200px",
              "&:hover": { backgroundColor: "black" },
            }}
          >
            Back to Courses
          </Button>
          <video
            src={currentCourse.video}
            controls
            style={{
              width: "100%",
              maxWidth: "800px",
              border: "1px solid #ddd",
              borderRadius: "8px",
            }}
          />
        </Box>
      ) : (
        // Course list screen
        <Box
          sx={{
            padding: 1,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
            alignItems: "flex-start",
            flexWrap: "wrap",
          }}
        >
          {courses.map((course, index) => (
            <Card
              key={index}
              sx={{
                maxWidth: { xs: "100%", sm: 300 },
                flex: "1 1 calc(50% - 16px)",
                mb: { xs: 2, sm: 0 },
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={course.image}
                alt={`${course.title} Course`}
              />
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
                >
                  {course.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 1.5, fontSize: { xs: "0.9rem", sm: "1rem" } }}
                >
                  {course.description}
                </Typography>
              </CardContent>
              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <IconButton aria-label="share" onClick={handleShare}>
                  <ShareIcon />
                </IconButton>
                <Button
                  variant="contained"
                  startIcon={<PlayCircleIcon />}
                  onClick={() => handleViewCourse(course)}
                  sx={{
                    marginLeft: "auto",
                    fontSize: { xs: "0.8rem", sm: "1rem" },
                    backgroundColor: "black",
                    "&:hover": { backgroundColor: "black" },
                  }}
                >
                  View Course
                </Button>
              </CardActions>
            </Card>
          ))}
        </Box>
      )}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="share-modal-title"
        aria-describedby="share-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography id="share-modal-title" variant="h6" component="h2">
              Share Course
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            label="Share this link"
            value={window.location.href}
            InputProps={{
              readOnly: true,
            }}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleCopyLink}
          >
            Copy Link
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default Courses;
